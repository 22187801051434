<template>
  <DropdownMenuSubContent
    v-bind="{
      ...$attrs,
      ...props,
      class: cn(
        'bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[8rem] overflow-hidden rounded-md border p-1 shadow-lg',
        props.class
      ),
    }"
  >
    <slot />
  </DropdownMenuSubContent>
</template>

<script setup lang="ts">
import {
  DropdownMenuSubContent,
  type DropdownMenuSubContentProps,
} from "radix-vue";

type Props = DropdownMenuSubContentProps & {
  class?: ClassProp;
};
const props = defineProps<Props>();
</script>
